<template>
  <div>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Search -->
        <b-col cols="12">
          <div class="d-flex align-items-center justify-end">
            <!-- <div class="d-flex align-items-center">
              <p class="search-label">Search</p>
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search by keyword"
              />
            </div> -->
            <b-button variant="primary" @click="() => openModal()">
              <span class="text-nowrap">Add Branch</span>
              <feather-icon
                icon="PlusCircleIcon"
                size="16"
                class="add-branch-icon"
              />
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0" v-if="!isBusy">
      <b-table
        ref="refListTable"
        class="position-relative mb-0"
        primary-key="id"
        responsive
        show-empty
        empty-text="No matching records found"
        :busy="isBusy"
        :items="form.branch"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        v-draggabletable="draggableOptions"
      >
        <!-- Column: User -->
        <template #cell(hamburger)>
          <feather-icon icon="MenuIcon" size="24" />
        </template>

        <!-- Column: main branch -->
        <template #cell(main)="data">
          <span>{{ data.item.main === 'Yes' ? 'Main Branch' : '-' }}</span>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveCardStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <div class="action-button">
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </div>
              </template>
              <b-dropdown-item @click="() => openModal(data.item)">
                <span class="align-middle ml-50">View &amp; Edit</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="confirmDelete(data.item.id)"
              >
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>
    </b-card>

    <!-- Pagination -->
    <div class="mt-2 mx-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="
            d-flex
            align-items-center
            justify-content-center justify-content-sm-start
          "
        >
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing </span>
            <b-form-select
              v-model="perPage"
              :options="perPageOptions"
              class="mx-1"
            />
            <span class="text-nowrap"> of {{ form.branch.length }} entries </span>
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="
            d-flex
            align-items-center
            justify-content-center justify-content-sm-end
          "
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="form.branch.length"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>

    <RestaurantBranchCreate :form="form" :values="values" :isEdit="isEdit" />
  </div>
</template>
  
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BImg,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownDivider,
  BDropdownItem,
  BFormSelect,
  BPagination,
  VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import useRestaurantBranch from "./useRestaurantBranch";
import { draggabletable } from "@/libs/vue-draggable.js";
import RestaurantBranchCreate from "../create/RestaurantBranchCreate.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BFormSelect,
    BPagination,
    BImg,

    vSelect,
    RestaurantBranchCreate,
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isBusy: false,
      isEdit: false,
      values: {
        main: 'No',
        name: '',
        businessHour: '',
        email: '',
        telephone: '',
        googlemap: '',
        status: 'Activate',
      }
    }
  },
  directives: {
    'b-modal': VBModal,
    draggabletable
  },
  methods: {
    openModal(values) {
      if (!!values) {
        this.isEdit = true
        this.values = JSON.parse(JSON.stringify(values))
      } else {
        this.isEdit = false
        this.values = {
          main: 'No',
          name: '',
          businessHour: '',
          email: '',
          telephone: '',
          googlemap: '',
          status: 'Activate',
        }
      }
      this.$bvModal.show('modal-restaurant-branch-create');
    },
    confirmDelete(position) {
      this.$swal({
        title: "Are you sure to delete?",
        text: "This will permanently erase your information. You can’t undo action.",
        icon: "warning",
        iconColor: "#FF9F43",
        showCancelButton: true,
        confirmButtonText: "Delete",
        customClass: {
          htmlContainer: "my-50",
          actions: "flex-row-reverse my-1",
          confirmButton: "btn btn-danger px-5",
          cancelButton: "btn btn-outline-secondary px-5 mr-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (!result.value) return;
        this.form.branch = this.form.branch
          .filter(item => item.id != position)
          .sort((a, b) => Number(a.id) - Number(b.id))
          .map((item, index) => ({ ...item, id: `${index + 1}` }))

        if (this.form.branch.length > 0 && this.form.branch.every(item => item.main === 'No')) {
          this.form.branch[0] = { ...this.form.branch[0], main: 'Yes' }
        }
      });
    },
    swapPosition(oldPosition, newPosition) {
      this.form.branch = this.form.branch.sort((a, b) => Number(a.id) - Number(b.id))
      const branch = this.form.branch.slice(oldPosition - 1, oldPosition)
      const branchWithoutOldPosition = this.form.branch.filter((_, index) => index !== oldPosition - 1)
      this.form.branch = [
        ...branchWithoutOldPosition.slice(0, newPosition - 1),
        ...branch,
        ...branchWithoutOldPosition.slice(newPosition - 1, this.form.branch.length),
      ].map((item, index) => ({ ...item, id: `${index + 1}` }))
    },
  },
  setup() {
    const {
      // data
      tableColumns,
      perPage,
      currentPage,
      totalCards,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      draggableOptions,

      // UI
      resolveCardStatusVariant,
    } = useRestaurantBranch();

    return {
      // data
      tableColumns,
      perPage,
      currentPage,
      totalCards,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      draggableOptions,

      // UI
      resolveCardStatusVariant,
    };
  },
};
</script>
  
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.search-label {
  margin: 0 10px 0 0;
}
.add-branch-icon {
  margin-left: 8px;
}
.action-button {
  border: 1px solid #6e6b7b;
  border-radius: 50%;
  padding: 5px;
}
</style>
