<template>
  <b-modal
    id="modal-restaurant-branch-create"
    centered
  >
    <template #modal-header="{ close }">
      <div class="flex w-full justify-between items-center py-1">
        <h3 class="mb-0">{{ isEdit ? 'Edit Branch' : 'Add Branch' }}</h3>
        <feather-icon
          class="cursor-pointer"
          icon="XIcon"
          size="20"
          @click="close"
        />
      </div>
    </template>

    <validation-observer ref="form">
      <b-form>
        <b-row>
          <b-col sm="12">
            <b-row>
                <b-col sm="12">
                  <h3>Content</h3>
                </b-col>
                <b-col sm="12">
                  <b-form-group>
                    <label for="main">Main Branch <span class="text-red">*</span></label>
                    <b-form-radio-group v-model="values.main">
                      <b-form-radio value="Yes">
                        Yes
                      </b-form-radio>
                      <b-form-radio value="No">
                        No
                      </b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group>
                    <label for="name">Branch Name <span class="text-red">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="branch name"
                      rules="required"
                    >
                      <b-form-input
                        v-model="values.name"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Branch Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group>
                    <label for="businessHour">Business Hour <span class="text-red">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="business hour"
                      rules="required"
                    >
                      <b-form-input
                        v-model="values.businessHour"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Business Hour"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group>
                    <label for="telephone">Telephone <span class="text-red">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="telephone"
                      rules="required"
                    >
                      <b-form-input
                        v-model="values.telephone"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Telephone"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group>
                    <label for="email">Email <span class="text-red">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="email address"
                      rules="required|email"
                    >
                      <b-form-input
                        v-model="values.email"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Email"
                        name="email"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group>
                    <label for="googlemap">Google Map Link <span class="text-red">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="googlemap"
                      rules="required"
                    >
                      <b-form-input
                        v-model="values.googlemap"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Google Map Link"
                        name="googlemap"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="12">
            <b-row>
                <b-col sm="12">
                  <h3>Status</h3>
                </b-col>
                <b-col sm="12">
                  <b-form-group>
                    <label for="status">Status <span class="text-red">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="status"
                      rules="required"
                    >
                      <v-select
                        v-model="values.status"
                        :state="errors.length > 0 ? false : null"
                        :options="statusOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="status"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <template #modal-footer="{ cancel, ok }">
      <div class="flex w-full">
        <b-button
          class="flex-grow"
          variant="outline-secondary"
          @click="cancel"
        >
          <span>Cancel</span>
        </b-button>
        <b-button
          class="flex-grow ml-1"
          variant="success"
          type="submit"
          @click="validationForm(ok)"
        >
          <span>Save</span>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import vSelect from 'vue-select'
import dayjs from 'dayjs'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BFormRadioGroup, BFormRadio, BFormFile, BButton, BModal, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg, BTab, BTabs, BFormTextarea } from 'bootstrap-vue'
import { required, email, regex } from '@validations'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import useRestaurantBranchCreate from './useRestaurantBranchCreate'
import restaurantStoreModule from '../../restaurantStoreModule'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BModal,
    BFormRadioGroup,
    BFormRadio,
    BButton,
    BForm,
    BImg,
    BFormTextarea,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BTab,
    BTabs,

    vSelect,
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    values: {
      type: Object,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      defualt: () => false,
    }
  },
  data() {
    return {
      required,
      regex,
      email,
    }
  },
  methods: {
    async validationForm(callback) {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return false;
      const form = {
        main: this.values.main,
        name: this.values.name,
        businessHour: this.values.businessHour,
        email: this.values.email,
        telephone: this.values.telephone,
        googlemap: this.values.googlemap,
        status: this.values.status,
        id: `${this.form.branch.length + 1}`,
        createdTime: dayjs(),
      }

      if (this.isEdit) {
        this.form.branch = this.form.branch.filter(item => item.id !== this.values.id)
        form.id = this.values.id
      }

      if (this.values.main === 'Yes') {
        this.form.branch = [
          ...this.form.branch,
          form,
        ].map(item => {
          if (item.id === form.id) return { ...item }
          return { ...item, main: 'No' }
        })
      } else if (this.form.branch.every(item => item.main === 'No')) {
        this.form.branch = [...this.form.branch, { ...form, main: 'Yes' }]
      } else {
        this.form.branch = [...this.form.branch, form]
      }
      callback();
    },
  },
  setup() {
    const RESTAURANT_APP_STORE_MODULE_NAME = 'app-restaurant'

    // Register module
    if (!store.hasModule(RESTAURANT_APP_STORE_MODULE_NAME)) store.registerModule(RESTAURANT_APP_STORE_MODULE_NAME, restaurantStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(RESTAURANT_APP_STORE_MODULE_NAME)) store.unregisterModule(RESTAURANT_APP_STORE_MODULE_NAME)
    })

    const {
      toast,
      addBranch,
      statusOptions,
    } = useRestaurantBranchCreate()

    return {
      toast,
      addBranch,
      statusOptions,
    }
  },
}
</script>