<template>
  <b-modal
    id="modal-restaurant-review-create"
    centered
  >
    <template #modal-header="{ close }">
      <div class="flex w-full justify-between items-center py-1">
        <h3 class="mb-0">{{ isEdit ? 'Edit Review' : 'Add Review' }}</h3>
        <feather-icon
          class="cursor-pointer"
          icon="XIcon"
          size="20"
          @click="close"
        />
      </div>
    </template>

    <validation-observer ref="form">
      <b-form>
        <b-row>
          <b-col sm="12">
            <b-row>
                <b-col sm="12">
                  <h3>Media</h3>
                </b-col>
                <b-col sm="12">
                  <b-row>
                    <b-col sm="12">
                      <div
                        class="flex flex-col preview-image dashed"
                        :hidden="values.fileUrl !== ''"
                        id="dropzone"
                        :class="loading ? 'pointer-none' : ''"
                      >
                        <div
                          class="flex flex-col items-center"
                          @click="$refs.file.$refs.input.click()"
                        >
                          <feather-icon icon="UploadCloudIcon" size="30" />
                          <span class="font-18px">Select your image</span>
                          <span>Recommened dimension : 800x800 px</span>
                          <span>Image: Max 700 KB (PNG, JPG)</span>
                        </div>
                      </div>
                      <b-img
                        :src="values.fileUrl"
                        :hidden="values.fileUrl === ''"
                        class="cursor-pointer avatar"
                        @click="$refs.file.$refs.input.click()"
                      />
                    </b-col>
                    <b-col sm="12" class="mt-12px">
                      <b-form-group>
                        <label for="banner-image">Image Upload <span class="text-red">*</span></label>
                        <validation-provider
                          #default="{ errors }"
                          ref="image"
                          name="image"
                          rules="required"
                        >
                          <b-form-file
                            ref="file"
                            :disabled="loading"
                            v-model="values.file"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Choose another file"
                            accept=".jpg, .png"
                            @input="inputImageRenderer"
                            :class="loading ? 'loading-form-file': ''"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="12">
            <b-row>
                <b-col sm="12">
                  <h3>SCORE</h3>
                </b-col>
                <b-col sm="12">
                  <b-form-group>
                    <label for="content-fullname">Review Score <span class="text-red">*</span></label>
                    <div>
                      <b-form-rating
                        v-model="values.rating"
                        inline
                        no-border
                        size="lg"
                        variant="warning"
                        class="p-0"
                      />
                    </div>
                  </b-form-group>
                </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="12">
            <b-row>
              <b-col sm="12">
                <h3>Content</h3>
              </b-col>
              <b-col sm="12">
                <b-tabs pills>
                    <b-tab title="English" active>
                      <b-form-group>
                        <label for="content-fullname">Fullname <span class="text-red">*</span></label>
                        <validation-provider
                          #default="{ errors }"
                          name="fullname"
                          rules="required"
                        >
                          <b-form-input
                            v-model="values.english.fullname"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Fullname"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <b-form-group>
                        <label for="content-review">Review <span class="text-red">*</span></label>
                        <validation-provider
                          #default="{ errors }"
                          name="review"
                          rules="required"
                        >
                          <b-form-textarea
                            v-model="values.english.review"
                            rows="5"
                            placeholder="review"
                            :maxlength="140"
                          />
                          <small v-if="errors.length === 0">{{ values.english.review.length }}/140</small>
                          <small v-else class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-tab>
                    <b-tab title="Thai">
                      <b-form-group>
                        <label for="content-fullname">Fullname</label>
                        <b-form-input
                          v-model="values.thai.fullname"
                          placeholder="Fullname"
                        />
                      </b-form-group>
                      <b-form-group>
                        <label for="content-review">Review</label>
                        <b-form-textarea
                          v-model="values.thai.review"
                          rows="5"
                          placeholder="Review"
                          :maxlength="140"
                        />
                        <small>{{ values.thai.review.length }}/140</small>
                      </b-form-group>
                    </b-tab>
                    <b-tab title="Chinese">
                      <b-form-group>
                        <label for="content-fullname">Fullname</label>
                        <b-form-input
                          v-model="values.chinese.fullname"
                          placeholder="Fullname"
                        />
                      </b-form-group>
                      <b-form-group>
                        <label for="content-review">Review</label>
                        <b-form-textarea
                          v-model="values.chinese.review"
                          rows="5"
                          placeholder="Review"
                          :maxlength="140"
                        />
                        <small>{{ values.chinese.review.length }}/140</small>
                      </b-form-group>
                    </b-tab>
                </b-tabs>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="12">
            <b-row>
                <b-col sm="12">
                  <h3>Status</h3>
                </b-col>
                <b-col sm="12">
                  <b-form-group>
                    <label for="status">Status <span class="text-red">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="status"
                      rules="required"
                    >
                      <v-select
                        v-model="values.status"
                        :state="errors.length > 0 ? false : null"
                        :options="statusOptions"
                        :reduce="(val) => val.value"
                        :clearable="false"
                        input-id="status"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <template #modal-footer="{ cancel, ok }">
      <div class="flex w-full">
        <b-button
          class="flex-grow"
          variant="outline-secondary"
          @click="cancel"
        >
          <span>Cancel</span>
        </b-button>
        <b-button
          class="flex-grow ml-1"
          variant="success"
          type="submit"
          @click="validationForm(ok)"
        >
          <span>Save</span>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import vSelect from "vue-select";
import Dropzone from 'dropzone';
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormRadioGroup,
  BFormRadio,
  BFormFile,
  BFormRating,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BTab,
  BTabs,
  BFormTextarea,
  BModal,
} from "bootstrap-vue";
import { required, email, regex } from "@validations";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import useRestaurantReviewCreate from "./useRestaurantReviewCreate";
import restaurantStoreModule from "../../restaurantStoreModule";
import dayjs from 'dayjs';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BFormRadioGroup,
    BFormRadio,
    BButton,
    BForm,
    BImg,
    BFormTextarea,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BFormRating,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BTab,
    BTabs,

    BModal,
    vSelect,
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    values: {
      type: Object,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      defualt: () => false,
    }
  },
  data() {
    return {
      required,
      regex,
      email,
      loading: false,
    };
  },
  methods: {
    async validationForm(callback) {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      if ((this.values.file.size / 1024 / 1024) > 0.7) {
        await this.$refs.image.validate()
        await this.$refs.image.setErrors(['The file size must not exceed the specified size'])
        return;
      }

      const form = {
        file: this.values.file,
        fileUrl: this.values.fileUrl,
        rating: this.values.rating,
        thai: this.values.thai,
        english: this.values.english,
        chinese: this.values.chinese,
        status: this.values.status,
        id: this.form.review.length + 1,
        createdTime: dayjs(),
      }

      if (this.isEdit) {
        this.form.review = this.form.review.filter(item => item.id !== this.values.id)
        form.id = this.values.id
      }

      this.form.review = [...this.form.review, form]
      callback();
    },
    async inputImageRenderer(file) {
      if (!file) return;
      if ((file.size / 1024 / 1024) > 0.7) {
        await this.$refs.image.validate()
        await this.$refs.image.setErrors(['The file size must not exceed the specified size'])
        return;
      }

      const form = new FormData();
      form.append("files", file);

      this.loading = true;
      const { data, status } = await this.uploadImage(form);

      if (status !== 200) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Error upload image",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        return;
      }

      this.values.fileUrl = data[0];
      this.loading = false;
    },
  },
  beforeUpdate() {
    setTimeout(() => {
      const dropzoneElement = document.getElementById('dropzone')
      if (dropzoneElement) {
        try {
          const dropZone = new Dropzone(dropzoneElement, { url: '/', autoProcessQueue: false, disablePreviews: true });
          dropZone.on('addedfile', async (file) => {
            this.$refs.file.setFiles([file])
          })
        } catch (_) {
          // ignored
        }
      }
    }, 100)
  },
  setup() {
    const RESTAURANT_APP_STORE_MODULE_NAME = "app-restaurant";

    // Register module
    if (!store.hasModule(RESTAURANT_APP_STORE_MODULE_NAME))
      store.registerModule(
        RESTAURANT_APP_STORE_MODULE_NAME,
        restaurantStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(RESTAURANT_APP_STORE_MODULE_NAME))
        store.unregisterModule(RESTAURANT_APP_STORE_MODULE_NAME);
    });

    const {
      toast,
      uploadImage,
      statusOptions
    } = useRestaurantReviewCreate();

    return {
      toast,
      uploadImage,
      statusOptions,
    };
  },
};
</script>
<style lang="scss" scoped>
.avatar {
  width: 120px;
  height: 120px;
}
</style>