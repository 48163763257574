<template>
  <b-row>
    <b-col sm="12" xl="9">
      <b-card>
        <b-row>
          <b-col sm="12">
            <h3>About</h3>
          </b-col>
          <b-col sm="6">
            <b-form-group>
              <label for="name">Restaurant Name <span class="text-red">*</span></label>
              <validation-provider
                #default="{ errors }"
                name="Restaurant Name"
                rules="required"
              >
                <b-form-input
                  v-model="form.info.name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Restaurant Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group>
              <label for="badge">Badge</label>
              <v-select
                v-model="form.info.badge"
                :options="badgeOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="badge"
              />
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group>
              <label for="short-description">Short Description <span class="text-red">*</span></label>
              <validation-provider
                #default="{ errors }"
                name="Restaurant short description"
                rules="required"
              >
                <b-form-textarea
                  v-model="form.info.description"
                  rows="5"
                  placeholder="Short Description"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-row>
              <b-col sm="12">
                <div
                  class="flex flex-col preview-image dashed"
                  :hidden="form.info.logoUrl !== ''"
                  id="dropzone-logo"
                  :class="loading ? 'pointer-none' : ''"
                >
                  <div
                    class="flex flex-col items-center"
                    @click="$refs.logo.$refs.input.click()"
                  >
                    <feather-icon icon="UploadCloudIcon" size="30" />
                    <span class="font-18px">Size dimension : 800x800 px</span>
                    <span>(Size not exceed 10 MB, PNG, JPG)</span>
                  </div>
                </div>
                <b-img
                  :src="form.info.logoUrl"
                  :hidden="form.info.logoUrl === ''"
                  class="image-full cursor-pointer"
                  @click="$refs.logo.$refs.input.click()"
                />
              </b-col>
              <b-col sm="12" class="mt-12px">
                <b-form-group>
                  <label for="banner-image">Logo Upload <span class="text-red">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    ref="logoValidate"
                    name="image"
                    rules="required"
                  >
                    <b-form-file
                      ref="logo"
                      :disabled="loading"
                      v-model="form.info.logo"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Choose another logo"
                      accept=".jpg, .png, .mp4"
                      @input="handleUploadLogo"
                      :class="loading ? 'loading-form-file': ''"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group>
                  <label for="name">Facebook <span class="text-red">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    name="Facebook"
                    rules="required"
                  >
                    <b-form-input
                      v-model="form.info.facebook"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Facebook"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group>
                  <label for="name">Instagram <span class="text-red">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    name="Instagram"
                    rules="required"
                  >
                    <b-form-input
                      v-model="form.info.instagram"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Instagram"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>    
            </b-row>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col sm="12" xl="9">
      <b-card>
        <b-row>
          <b-col sm="12">
            <h3>Feature Image</h3>
          </b-col>
          <b-col sm="12">
            <b-tabs pills>
                <b-tab title="Daylife" active>
                  <b-row>
                      <b-col sm="12">
                        <div
                          class="flex flex-col preview-image dashed"
                          :hidden="form.info.featureImage.daylifeUrl !== ''"
                          id="dropzone-daylife"
                          :class="loading ? 'pointer-none' : ''"
                        >
                          <div
                            class="flex flex-col items-center"
                            @click="$refs.daylife.$refs.input.click()"
                          >
                            <feather-icon icon="UploadCloudIcon" size="30" />
                            <span class="font-18px">Size dimension : 800x800 px</span>
                            <span>(Size not exceed 10 MB, PNG, JPG)</span>
                          </div>
                        </div>
                        <b-img
                          :src="form.info.featureImage.daylifeUrl"
                          :hidden="form.info.featureImage.daylifeUrl === ''"
                          class="image-full cursor-pointer"
                          @click="$refs.daylife.$refs.input.click()"
                        />
                      </b-col>
                      <b-col sm="12" class="mt-12px">
                        <b-form-group>
                          <label for="image-daylife">Image Upload</label>
                          <b-form-file
                            ref="daylife"
                            :disabled="loading"
                            v-model="form.info.featureImage.daylife"
                            placeholder="Choose another image"
                            accept=".jpg, .png, .mp4"
                            @input="(file) => handleUploadFeatureImage(file, true)"
                            :class="loading ? 'loading-form-file': ''"
                          />
                        </b-form-group>
                      </b-col>
                  </b-row>
                </b-tab>
                <b-tab title="Nightlife">
                  <b-row>
                    <b-col sm="12">
                        <div
                          class="flex flex-col preview-image dashed"
                          :hidden="form.info.featureImage.nightlifeUrl !== ''"
                          id="dropzone-nightlife"
                          :class="loading ? 'pointer-none' : ''"
                        >
                          <div
                            class="flex flex-col items-center"
                            @click="$refs.nightlife.$refs.input.click()"
                          >
                            <feather-icon icon="UploadCloudIcon" size="30" />
                            <span class="font-18px">Size dimension : 800x800 px</span>
                            <span>(Size not exceed 10 MB, PNG, JPG)</span>
                          </div>
                        </div>
                        <b-img
                          :src="form.info.featureImage.nightlifeUrl"
                          :hidden="form.info.featureImage.nightlifeUrl === ''"
                          class="image-full cursor-pointer"
                          @click="$refs.nightlife.$refs.input.click()"
                        />
                    </b-col>
                    <b-col sm="12" class="mt-12px">
                        <b-form-group>
                          <label for="image-nightlife">Image Upload</label>
                          <b-form-file
                            ref="nightlife"
                            :disabled="loading"
                            v-model="form.info.featureImage.nightlife"
                            placeholder="Choose another image"
                            accept=".jpg, .png, .mp4"
                            @input="(file) => handleUploadFeatureImage(file, false)"
                            :class="loading ? 'loading-form-file': ''"
                          />
                        </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col sm="12" xl="9">
      <b-card>
        <b-row>
          <b-col sm="12">
            <h3>Content</h3>
          </b-col>
          <b-col sm="12">
            <b-tabs pills>
              <b-tab title="English" active>
                <b-form-group>
                  <label for="content-whoweare-section1"
                    >Restaurant Content (Section 1)
                    <span class="text-red">*</span></label
                  >
                  <validation-provider
                    #default="{ errors }"
                    name="Section1"
                    rules="required"
                  >
                    <b-form-textarea
                      v-model="form.info.content.english.section1"
                      rows="5"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Restaurant Content (Section 1)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group>
                  <label for="content-whoweare-section2"
                    >Restaurant Content (Section 2)</label
                  >
                  <b-form-textarea
                    v-model="form.info.content.english.section2"
                    rows="5"
                    placeholder="Restaurant Content (Section 2)"
                  />
                </b-form-group>
              </b-tab>
              <b-tab title="Thai">
                <b-form-group>
                  <label for="content-whoweare-section1"
                    >Restaurant Content (Section 1)</label
                  >
                  <b-form-input
                    v-model="form.info.content.thai.section1"
                    placeholder="Restaurant Content (Section 1)"
                  />
                </b-form-group>
                <b-form-group>
                  <label for="content-whoweare-section2"
                    >Restaurant Content (Section 2)</label
                  >
                  <b-form-textarea
                    v-model="form.info.content.thai.section2"
                    rows="5"
                    placeholder="Restaurant Content (Section 2)"
                  />
                </b-form-group>
              </b-tab>
              <b-tab title="Chinese">
                <b-form-group>
                  <label for="content-whoweare-section1"
                    >Restaurant Content (Section 1)</label
                  >
                  <b-form-input
                    v-model="form.info.content.chinese.section1"
                    placeholder="Restaurant Content (Section 1)"
                  />
                </b-form-group>
                <b-form-group>
                  <label for="content-whoweare-section2"
                    >Restaurant Content (Section 2)</label
                  >
                  <b-form-textarea
                    v-model="form.info.content.chinese.section2"
                    rows="5"
                    placeholder="Restaurant Content (Section 2)"
                  />
                </b-form-group>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col sm="12" xl="9">
      <b-card>
        <b-row>
          <b-col sm="12">
            <h3>Gallery</h3>
          </b-col>
          <b-col sm="12">
            <b-form-group>
              <label for="about-image">Restaurant Image <span class="text-red">*</span></label>
              <div
                class="flex flex-col preview-image dashed"
                id="dropzone-gallery"
                :class="loading ? 'pointer-none' : ''"
              >
                <div
                  class="flex flex-col items-center"
                  @click="$refs.images.$refs.input.click()"
                >
                  <feather-icon icon="UploadCloudIcon" size="30" />
                  <span class="font-18px" :hidden="loading">Size dimension 1,200x675 px</span>
                  <span class="font-18px" :hidden="!loading">Uploading</span>
                  <span :hidden="loading">(Size not exceed 10 MB, PNG, JPG)</span>
                </div>
              </div>
              <div class="flex preview-gallery">
                <div
                  class="relative"
                  v-for="(image, index) in form.info.images"
                  :key="index"
                >
                  <b-img :src="image" width="200" height="200" />
                  <div class="close-button" @click="removeFileUpload(index)">
                    <feather-icon icon="XIcon" size="16" />
                  </div>
                </div>
              </div>
              <validation-provider
                #default="{ errors }"
                ref="gallery"
                name="image"
                rules="required"
              >
                <b-form-file
                  ref="images"
                  v-model="form.info.temp"
                  placeholder="Choose file"
                  accept=".jpg, .png"
                  multiple
                  :disabled="loading"
                  @input="(files) => handleUploadImage(files)"
                  class="display-none"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col sm="12" xl="9">
      <b-card>
        <b-row>
          <b-col sm="12">
            <h3>Status</h3>
          </b-col>
          <b-col sm="12">
            <b-form-group>
              <label for="status">Status <span class="text-red">*</span></label>
              <validation-provider
                #default="{ errors }"
                name="status"
                rules="required"
              >
                <v-select
                  v-model="form.info.status"
                  :state="errors.length > 0 ? false : null"
                  :options="statusOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="status"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import vSelect from "vue-select";
import Dropzone from 'dropzone'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormRadioGroup,
  BFormRadio,
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BTab,
  BTabs,
  BFormTextarea,
  BInputGroup,
} from "bootstrap-vue";
import { required, email, regex } from "@validations";
import useRestaurantCreate from "./useRestaurantCreate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BFormRadioGroup,
    BFormRadio,
    BButton,
    BForm,
    BImg,
    BFormTextarea,
    BFormFile,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BTab,
    BTabs,

    vSelect,
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      regex,
      email,
      loading: false,
    };
  },
  methods: {
    async uploadImageWithLoading(form) {
      this.loading = true;
      const { data, status } = await this.uploadImage(form);

      if (status !== 200) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Error upload image",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        this.loading = false;
        return;
      }

      this.loading = false;
      return data;
    },
    async handleUploadLogo(file) {
      if (!file) return;
      if ((file.size / 1024 / 1024) > 10) {
        await this.$refs.logoValidate.validate()
        await this.$refs.logoValidate.setErrors(['The file size must not exceed the specified size'])
        return;
      }

      const form = new FormData();
      form.append("files", file);

      const data = await this.uploadImageWithLoading(form);
      this.form.info.logoUrl = data[0];
    },
    async handleUploadFeatureImage(file, isDaylife) {
      if (!file) return;
      if ((file.size / 1024 / 1024) > 10) {
        if (isDaylife) {
          await this.$refs.imgDaylife.validate()
          await this.$refs.imgDaylife.setErrors(['The file size must not exceed the specified size'])
          return;
        } else {
          await this.$refs.imgNightlife.validate()
          await this.$refs.imgNightlife.setErrors(['The file size must not exceed the specified size'])
          return;
        }
      }

      const form = new FormData();
      form.append("files", file);

      const data = await this.uploadImageWithLoading(form);
      if (isDaylife) {
        this.form.info.featureImage.daylifeUrl = data[0];
      } else {
        this.form.info.featureImage.nightlifeUrl = data[0];
      }
    },
    async handleUploadImage(files) {
      if (!files || files.length === 0) return;
      if (files.some(file => (file.size / 1024 / 1024) > 10)) {
        await this.$refs.gallery.validate()
        await this.$refs.gallery.setErrors(['The file size must not exceed the specified size'])
        return;
      }

      const form = new FormData();
      for (const file of files) {
        form.append("files", file);
      }

      const data = await this.uploadImageWithLoading(form);
      this.form.info.images = [...this.form.info.images, ...data];
    },
    removeFileUpload(index) {
      this.form.info.images = this.removeItemInArray(this.form.info.images, index);
    },
    removeItemInArray(array, index) {
      const temp = [...array];
      let tempLength = 0;
      for (let i = 0; i < array.length; i++) {
        if (i !== index) {
          temp[tempLength++] = temp[i];
        }
      }
      temp.length = tempLength;
      return temp;
    },
  },
  mounted() {
    const dropzoneLogoElement = document.getElementById('dropzone-logo')
    const dropzoneDaylifeElement = document.getElementById('dropzone-daylife')
    const dropzoneNightlifeElement = document.getElementById('dropzone-nightlife')
    const dropzoneGalleryElement = document.getElementById('dropzone-gallery')
    if (dropzoneLogoElement) {
      const dropzoneLogo = new Dropzone(dropzoneLogoElement, { url: '/', autoProcessQueue: false, disablePreviews: true });
      dropzoneLogo.on('addedfile', async (file) => {
        this.$refs.logo.setFiles([file])
      })
    }
    if (dropzoneDaylifeElement) {
      const dropzoneDaylife = new Dropzone(dropzoneDaylifeElement, { url: '/', autoProcessQueue: false, disablePreviews: true });
      dropzoneDaylife.on('addedfile', async (file) => {
        this.$refs.daylife.setFiles([file])
      })
    }
    if (dropzoneNightlifeElement) {
      const dropzoneNightlife = new Dropzone(dropzoneNightlifeElement, { url: '/', autoProcessQueue: false, disablePreviews: true });
      dropzoneNightlife.on('addedfile', async (file) => {
        this.$refs.nightlife.setFiles([file])
      })
    }
    if (dropzoneGalleryElement) {
      const dropzoneGallery = new Dropzone(dropzoneGalleryElement, { url: '/', autoProcessQueue: false, disablePreviews: true, parallelUploads:10, uploadMultiple: true });
      dropzoneGallery.on('addedfiles', async (files) => {
        this.$refs.images.setFiles([...files])
      })
    }
  },
  setup() {
    const { toast, statusOptions, typeOptions, badgeOptions, uploadImage } =
      useRestaurantCreate();

    return {
      toast,
      statusOptions,
      typeOptions,
      badgeOptions,
      uploadImage,
    };
  },
};
</script>
