<template>
  <b-row>
    <b-col sm="12" xl="9">
      <b-card>
        <b-row>
          <b-col sm="12">
            <h3>Style</h3>
          </b-col>
          <b-col sm="12">
            <b-row>
              <b-col sm="6">
                <b-form-group>
                  <label for="fontTitle">Font Title <span class="text-red">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    name="font title"
                    rules="required"
                  >
                    <v-select
                      v-model="form.style.fontTitle"
                      :state="errors.length > 0 ? false : null"
                      :options="fontTitleOptions"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      input-id="fontTitle"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group>
                  <label for="fontBody">Font Body <span class="text-red">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    name="font body"
                    rules="required"
                  >
                    <v-select
                      v-model="form.style.fontBody"
                      :state="errors.length > 0 ? false : null"
                      :options="fontBodyOptions"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      input-id="fontBody"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="12">
            <b-row>
              <b-col sm="6">
                <b-form-group>
                  <label for="mainColor">Main Colour <span class="text-red">*</span></label>
                  <validation-provider
                    #default="{ valid, errors }"
                    name="Main Colour"
                    :rules="{
                      required: true,
                      regex: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/
                    }"
                  >
                    <div class="flex gap-4 w-full">
                      <div class="hex-box" :style="valid ? `background-color: ${form.style.color.main}` : ''"></div>
                      <div class="flex flex-col w-full">
                        <span class="hex-title">HEX</span>
                        <b-form-input
                          v-model="form.style.color.main"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Colour Hex"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </div>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group>
                  <label for="mainColor">Secondary Colour <span class="text-red">*</span></label>
                  <validation-provider
                    #default="{ valid, errors }"
                    name="Secondary Colour"
                    :rules="{
                      required: true,
                      regex: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/
                    }"
                  >
                    <div class="flex gap-4 w-full">
                      <div class="hex-box" :style="valid ? `background-color: ${form.style.color.secondary}` : ''"></div>
                      <div class="flex flex-col w-full">
                        <span class="hex-title">HEX</span>
                        <b-form-input
                          v-model="form.style.color.secondary"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Colour Hex"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </div>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <b-row>
          <b-col sm="12">
            <h3>Image Style</h3>
          </b-col>
          <b-col sm="12">
            <b-row>
              <b-col sm="12" md="4">
                <div class="flex flex-col">
                  <div class="draft-menu"></div>
                  <div class="draft-banner">Banner</div>
                  <div class="draft-component">Component A</div>
                  <div class="draft-body"></div>
                  <div class="draft-component">Component B</div>
                </div>
              </b-col>
              <b-col sm="12" md="8">
                <b-row>
                  <b-col sm="12">
                    <div 
                      class="flex flex-col preview-image dashed h-150px"
                      :hidden="form.style.bannerUrl !== ''"
                      id="dropzone-banner"
                      :class="loading ? 'pointer-none' : ''"
                    >
                      <div
                        class="flex flex-col items-center"
                        @click="$refs.banner.$refs.input.click()"
                      >
                        <feather-icon
                          icon="UploadCloudIcon"
                          size="30"
                        />
                        <span class="font-18px">Size dimension 1,440 x 400 px</span>
                        <span>(Size not exceed 700 KB - PNG, JPG, MP4)</span>
                      </div>
                    </div>
                    <b-img
                      v-if="form.style.bannerUrl !== '' && getFileExtension(form.style.bannerUrl) !== 'mp4'"
                      :src="form.style.bannerUrl"
                      class="image-full cursor-pointer"
                      style="height: 400px;"
                      @click="$refs.banner.$refs.input.click()"
                    />
                    <video
                      v-if="form.style.bannerUrl !== '' && getFileExtension(form.style.bannerUrl) === 'mp4'"
                      width="320"
                      controls
                    >
                      <source :src="form.style.bannerUrl">
                    </video>
                  </b-col>
                  <b-col sm="12" class="mt-12px">
                    <b-form-group>
                      <label for="banner-image">Banner Upload (1440x400px) <span class="text-red">*</span></label>
                      <validation-provider
                        #default="{ errors }"
                        ref="bannerUrl"
                        name="banner image"
                        rules="required"
                      >
                        <b-form-file
                          ref="banner"
                          :disabled="loading"
                          v-model="form.style.banner"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Choose another file"
                          accept=".jpg, .png, .mp4"
                          @input="(file) => handleUploadImage('bannerUrl', file)"
                          :class="loading ? 'loading-form-file': ''"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12">
                    <div 
                      class="flex flex-col preview-image dashed h-150px"
                      :hidden="form.style.componentaUrl !== ''"
                      id="dropzone-componenta"
                      :class="loading ? 'pointer-none' : ''"
                    >
                      <div
                        class="flex flex-col items-center"
                        @click="$refs.componenta.$refs.input.click()"
                      >
                        <feather-icon
                          icon="UploadCloudIcon"
                          size="30"
                        />
                        <span class="font-18px">Size dimension 1,440 x 100 px</span>
                        <span>(Size not exceed 700 KB - PNG, JPG)</span>
                      </div>
                    </div>
                    <div class="relative">
                      <b-img
                        :src="form.style.componentaUrl"
                        :hidden="form.style.componentaUrl === ''"
                        class="image-full cursor-pointer"
                        style="height: 100px;"
                        @click="$refs.componenta.$refs.input.click()"
                      />
                      <div
                        :hidden="form.style.componentaUrl === ''"
                        class="close-button"
                        @click="removeFileUpload('componenta')"
                      >
                        <feather-icon icon="XIcon" size="16" />
                      </div>
                    </div>
                  </b-col>
                  <b-col sm="12" class="mt-12px">
                    <b-form-group>
                      <label for="componenta-image">Component A Upload (1440x100px)</label>
                      <b-form-file
                        ref="componenta"
                        :disabled="loading"
                        v-model="form.style.componenta"
                        placeholder="Choose another file"
                        accept=".jpg, .png"
                        @input="file => handleUploadImage('componentaUrl', file)"
                        :class="loading ? 'loading-form-file': ''"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12">
                    <div 
                      class="flex flex-col preview-image dashed h-150px"
                      :hidden="form.style.componentbUrl !== ''"
                      id="dropzone-componentb"
                      :class="loading ? 'pointer-none' : ''"
                    >
                      <div
                        class="flex flex-col items-center"
                        @click="$refs.componentb.$refs.input.click()"
                      >
                        <feather-icon
                          icon="UploadCloudIcon"
                          size="30"
                        />
                        <span class="font-18px">Size dimension 1,440 x 100 px</span>
                        <span>(Size not exceed 700 KB - PNG, JPG)</span>
                      </div>
                    </div>
                    <div class="relative">
                      <b-img
                        :src="form.style.componentbUrl"
                        :hidden="form.style.componentbUrl === ''"
                        class="image-full cursor-pointer"
                        style="height: 140px;"
                        @click="$refs.componentb.$refs.input.click()"
                      />
                      <div
                        :hidden="form.style.componentbUrl === ''"
                        class="close-button"
                        @click="removeFileUpload('componentb')"
                      >
                        <feather-icon icon="XIcon" size="16" />
                      </div>
                    </div>
                  </b-col>
                  <b-col sm="12" class="mt-12px">
                    <b-form-group>
                      <label for="componentb-image">Component B Upload (1440x140px)</label>
                      <b-form-file
                        ref="componentb"
                        :disabled="loading"
                        v-model="form.style.componentb"
                        placeholder="Choose another file"
                        accept=".jpg, .png"
                        @input="(file) => handleUploadImage('componentbUrl', file)"
                        :class="loading ? 'loading-form-file': ''"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import vSelect from "vue-select";
import Dropzone from 'dropzone';
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormRadioGroup,
  BFormRadio,
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BTab,
  BTabs,
  BFormTextarea,
  BInputGroup,
} from "bootstrap-vue";
import { required, email, regex } from "@validations";
import useRestaurantCreate from "./useRestaurantCreate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BFormRadioGroup,
    BFormRadio,
    BButton,
    BForm,
    BImg,
    BFormTextarea,
    BFormFile,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BTab,
    BTabs,

    vSelect,
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      regex,
      email,
      loading: false,
    };
  },
  methods: {
    getFileExtension(filename) {
        return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
    },
    removeFileUpload(component) {
      this.form.style[component] = null
      this.form.style[`${component}Url`] = '';
    },
    async handleUploadImage(component, file) {
      if (!file) return;
      if ((file.size / 1024 / 1024) > 10) {
        await this.$refs[component].validate()
        await this.$refs[component].setErrors(['The file size must not exceed the specified size'])
        return;
      }

      const form = new FormData();
      form.append("files", file);

      this.loading = true;
      const { data, status } = await this.uploadImage(form);

      if (status !== 200) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Error upload image",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        this.loading = false;
        return;
      }

      this.loading = false;
      this.form.style[component] = data[0];
    },
  },
  mounted() {
    const dropzoneBannerElement = document.getElementById('dropzone-banner')
    const dropzoneComponentaElement = document.getElementById('dropzone-componenta')
    const dropzoneComponentbElement = document.getElementById('dropzone-componentb')
    if (dropzoneBannerElement) {
      const dropzoneBanner = new Dropzone(dropzoneBannerElement, { url: '/', autoProcessQueue: false, disablePreviews: true });
      dropzoneBanner.on('addedfile', async (file) => {
        this.$refs.banner.setFiles([file])
      })
    }
    if (dropzoneComponentaElement) {
      const dropzoneComponenta = new Dropzone(dropzoneComponentaElement, { url: '/', autoProcessQueue: false, disablePreviews: true });
      dropzoneComponenta.on('addedfile', async (file) => {
        this.$refs.componenta.setFiles([file])
      })
    }
    if (dropzoneComponentbElement) {
      const dropzoneComponentb = new Dropzone(dropzoneComponentbElement, { url: '/', autoProcessQueue: false, disablePreviews: true });
      dropzoneComponentb.on('addedfile', async (file) => {
        this.$refs.componentb.setFiles([file])
      })
    }
  },
  setup() {
    const {
      toast,
      uploadImage,
      fontTitleOptions,
      fontBodyOptions,
    } = useRestaurantCreate();

    return {
      toast,
      fontTitleOptions,
      fontBodyOptions,
      uploadImage,
    };
  },
};
</script>

<style scoped>
.hex-box {
  width: 100%;
  max-width: 60px;
  height: 60px;
  background-color: #D9D9D9;
  border-radius: 8px;
}
.hex-title {
  font-size: 0.857rem;
  margin-bottom: 4px;
}
.draft-menu {
  height: 16px;
  width: 100%;
  border: 1px solid #82868B;
}
.draft-banner {
  height: 90px;
  width: 100%;
  border: 1px solid #82868B;
  border-top: 0;
  border-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.draft-component {
  height: 25;
  width: 100%;
  border: 1px solid #82868B;
  display: flex;
  justify-content: center;
  align-items: center;
}
.draft-body {
  height: 250px;
  width: 100%;
  border: 1px solid #82868B;
  border-top: 0;
  border-bottom: 0;
}
.h-150px {
  height: 150px;
}
</style>