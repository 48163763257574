var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"sm":"12","xl":"9"}},[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('h3',[_vm._v("Category")])]),_c('b-col',{staticClass:"mt-1",attrs:{"sm":"12"}},[_c('b-form-checkbox-group',{model:{value:(_vm.form.setting.category),callback:function ($$v) {_vm.$set(_vm.form.setting, "category", $$v)},expression:"form.setting.category"}},[_c('b-form-checkbox',{attrs:{"value":"Daylife"}},[_vm._v("Daylife")]),_c('b-form-checkbox',{attrs:{"value":"Nightlife"}},[_vm._v("Nightlife")]),_c('b-form-checkbox',{attrs:{"value":"Stay with Us"}},[_vm._v("Stay with Us")])],1)],1),_c('b-col',{staticClass:"mt-2",attrs:{"sm":"12"}},[_c('h3',[_vm._v("Type")])]),_c('b-col',{staticClass:"mt-1",attrs:{"sm":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"eating","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"eating"}},[_vm._v("Eating Style Keywords "),_c('span',{staticClass:"text-red"},[_vm._v("*")])]),_c('v-select',{attrs:{"options":_vm.eatingOption,"multiple":""},model:{value:(_vm.form.setting.keywords.eating),callback:function ($$v) {_vm.$set(_vm.form.setting.keywords, "eating", $$v)},expression:"form.setting.keywords.eating"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"lifestyle","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"lifestyle"}},[_vm._v("Lifestyle Keywords "),_c('span',{staticClass:"text-red"},[_vm._v("*")])]),_c('v-select',{attrs:{"options":_vm.lifestyleOption,"multiple":""},model:{value:(_vm.form.setting.keywords.lifestyle),callback:function ($$v) {_vm.$set(_vm.form.setting.keywords, "lifestyle", $$v)},expression:"form.setting.keywords.lifestyle"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('h3',[_vm._v("SEO")])]),_c('b-col',{attrs:{"sm":"12"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"about-image"}},[_vm._v("OG Image "),_c('span',{staticClass:"text-red"},[_vm._v("*")])]),_c('div',{staticClass:"flex flex-col preview-image dashed",class:_vm.loading ? 'pointer-none' : '',attrs:{"hidden":_vm.form.seo.imageUrl !== '',"id":"dropzone"}},[_c('div',{staticClass:"flex flex-col items-center",on:{"click":function($event){return _vm.$refs.file.$refs.input.click()}}},[_c('feather-icon',{attrs:{"icon":"UploadCloudIcon","size":"30"}}),(!_vm.loading)?_c('div',{staticClass:"flex flex-col"},[_c('span',{staticClass:"font-18px"},[_vm._v("Size dimension 800x800 px")]),_c('span',[_vm._v("(Size not exceed 10 MB, PNG, JPG)")])]):_c('span',{staticClass:"font-18px"},[_vm._v("Loading...")])],1)]),_c('div',{staticClass:"relative",attrs:{"hidden":_vm.form.seo.imageUrl === ''}},[_c('b-img',{staticClass:"image-full",attrs:{"src":_vm.form.seo.imageUrl}}),_c('div',{staticClass:"close-button",on:{"click":_vm.resetFileUpload}},[_c('feather-icon',{attrs:{"icon":"XIcon","size":"16"}})],1)],1),_c('validation-provider',{ref:"image",attrs:{"name":"image","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{ref:"file",staticClass:"display-none",attrs:{"disabled":_vm.loading,"placeholder":"Choose file","accept":".jpg, .png"},on:{"input":_vm.handleUploadImage},model:{value:(_vm.form.seo.image),callback:function ($$v) {_vm.$set(_vm.form.seo, "image", $$v)},expression:"form.seo.image"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-tabs',{attrs:{"pills":""}},[_c('b-tab',{attrs:{"active":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"flex items-center"},[_c('span',[_vm._v("English")])])]},proxy:true}])},[_c('b-form-group',[_c('label',{attrs:{"for":"content-english-slug"}},[_vm._v("Page URL (Slug)")]),_c('validation-provider',{ref:"slugenglish",attrs:{"name":"slug","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"https://akarahospitality.com/restaurants"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-input-group-text',[(_vm.slugloading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1)]},proxy:true}],null,true)},[_c('b-form-input',{staticStyle:{"border-right":"0"},attrs:{"debounce":"200"},on:{"change":function($event){return _vm.changeSlug('english')}},model:{value:(_vm.form.seo.content.english.url),callback:function ($$v) {_vm.$set(_vm.form.seo.content.english, "url", $$v)},expression:"form.seo.content.english.url"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',{attrs:{"for":"content-english-title"}},[_vm._v("Meta Tag - Title")]),_c('b-form-input',{class:_vm.form.seo.content.english.title.length >= 100
                      ? 'text-danger'
                      : '',attrs:{"placeholder":"Meta Tag - Title","state":_vm.form.seo.content.english.title.length >= 100 ? false : null},model:{value:(_vm.form.seo.content.english.title),callback:function ($$v) {_vm.$set(_vm.form.seo.content.english, "title", $$v)},expression:"form.seo.content.english.title"}}),_c('span',{staticClass:"font-12px"},[_vm._v(_vm._s(_vm.form.seo.content.english.title.length)+"/100")])],1),_c('b-form-group',[_c('label',{attrs:{"for":"content-english-description"}},[_vm._v("Meta Tag - Description")]),_c('b-form-textarea',{class:_vm.form.seo.content.english.description.length >= 200
                      ? 'text-danger'
                      : '',attrs:{"rows":"5","placeholder":"Meta Tag - Description","state":_vm.form.seo.content.english.description.length >= 200
                      ? false
                      : null},model:{value:(_vm.form.seo.content.english.description),callback:function ($$v) {_vm.$set(_vm.form.seo.content.english, "description", $$v)},expression:"form.seo.content.english.description"}}),_c('span',{staticClass:"font-12px"},[_vm._v(_vm._s(_vm.form.seo.content.english.description.length)+"/200")])],1)],1),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"flex items-center"},[_c('span',[_vm._v("Thai")])])]},proxy:true}])},[_c('b-form-group',[_c('label',{attrs:{"for":"content-thai-slug"}},[_vm._v("Page URL (Slug)")]),_c('validation-provider',{ref:"slugthai",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"https://akarahospitality.com/restaurants"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-input-group-text',[(_vm.slugloading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1)]},proxy:true}],null,true)},[_c('b-form-input',{staticStyle:{"border-right":"0"},attrs:{"debounce":"200","disabled":_vm.slugloading},on:{"change":function($event){return _vm.changeSlug('thai')}},model:{value:(_vm.form.seo.content.thai.url),callback:function ($$v) {_vm.$set(_vm.form.seo.content.thai, "url", $$v)},expression:"form.seo.content.thai.url"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',{attrs:{"for":"content-thai-title"}},[_vm._v("Meta Tag - Title")]),_c('b-form-input',{class:_vm.form.seo.content.thai.title.length >= 100
                      ? 'text-danger'
                      : '',attrs:{"placeholder":"Meta Tag - Title","state":_vm.form.seo.content.thai.title.length >= 100 ? false : null},model:{value:(_vm.form.seo.content.thai.title),callback:function ($$v) {_vm.$set(_vm.form.seo.content.thai, "title", $$v)},expression:"form.seo.content.thai.title"}}),_c('span',{staticClass:"font-12px"},[_vm._v(_vm._s(_vm.form.seo.content.thai.title.length)+"/100")])],1),_c('b-form-group',[_c('label',{attrs:{"for":"content-thai-description"}},[_vm._v("Meta Tag - Description")]),_c('b-form-textarea',{class:_vm.form.seo.content.thai.description.length >= 200
                      ? 'text-danger'
                      : '',attrs:{"rows":"5","placeholder":"Meta Tag - Description","state":_vm.form.seo.content.thai.description.length >= 200
                      ? false
                      : null},model:{value:(_vm.form.seo.content.thai.description),callback:function ($$v) {_vm.$set(_vm.form.seo.content.thai, "description", $$v)},expression:"form.seo.content.thai.description"}}),_c('span',{staticClass:"font-12px"},[_vm._v(_vm._s(_vm.form.seo.content.thai.description.length)+"/200")])],1)],1),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"flex items-center"},[_c('span',[_vm._v("Chinese")])])]},proxy:true}])},[_c('b-form-group',[_c('label',{attrs:{"for":"content-chinese-slug"}},[_vm._v("Page URL (Slug)")]),_c('validation-provider',{ref:"slugchinese",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"https://akarahospitality.com/restaurants/"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-input-group-text',[(_vm.slugloading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1)]},proxy:true}],null,true)},[_c('b-form-input',{staticStyle:{"border-right":"0"},attrs:{"debounce":"200","disabled":_vm.slugloading},on:{"change":function($event){return _vm.changeSlug('chinese')}},model:{value:(_vm.form.seo.content.chinese.url),callback:function ($$v) {_vm.$set(_vm.form.seo.content.chinese, "url", $$v)},expression:"form.seo.content.chinese.url"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',{attrs:{"for":"content-chinese-title"}},[_vm._v("Meta Tag - Title")]),_c('b-form-input',{class:_vm.form.seo.content.chinese.title.length >= 100
                      ? 'text-danger'
                      : '',attrs:{"placeholder":"Meta Tag - Title","state":_vm.form.seo.content.chinese.title.length >= 100 ? false : null},model:{value:(_vm.form.seo.content.chinese.title),callback:function ($$v) {_vm.$set(_vm.form.seo.content.chinese, "title", $$v)},expression:"form.seo.content.chinese.title"}}),_c('span',{staticClass:"font-12px"},[_vm._v(_vm._s(_vm.form.seo.content.chinese.title.length)+"/100")])],1),_c('b-form-group',[_c('label',{attrs:{"for":"content-chinese-description"}},[_vm._v("Meta Tag - Description")]),_c('b-form-textarea',{class:_vm.form.seo.content.chinese.description.length >= 200
                      ? 'text-danger'
                      : '',attrs:{"rows":"5","placeholder":"Meta Tag - Description","state":_vm.form.seo.content.chinese.description.length >= 200
                      ? false
                      : null},model:{value:(_vm.form.seo.content.chinese.description),callback:function ($$v) {_vm.$set(_vm.form.seo.content.chinese, "description", $$v)},expression:"form.seo.content.chinese.description"}}),_c('span',{staticClass:"font-12px"},[_vm._v(_vm._s(_vm.form.seo.content.chinese.description.length)+"/200")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }