import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useRestaurantMenu() {
  // Use toast
  const toast = useToast()
  const refListTable = ref(null)

  // Options
  const categoryOptions = [
    { value: '', label: 'All' }
  ]

  // Table Handlers
  const tableColumns = [
    { key: 'hamburger', label: '', class: 'text-center' },
    { key: 'id', label: '#', class: 'text-center' },
    { key: 'imageUrl', label: 'image' },
    { key: 'english.menuName', label: 'title' },
    { key: 'english.menuDescription', label: 'description' },
    { key: 'status' },
    { key: 'createdTime', label: 'last updated' },
    { key: 'actions', class: 'text-center' },
  ]
  const perPage = ref(10)
  const totalCards = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const categoryQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(false)
  const draggableOptions = ref({
    animation: 400,
  })

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCards.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const uploadImage = async (images) => {
    try {
      const response = await store.dispatch('app-restaurant/uploadImage', images);
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error upload image',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveCardStatusVariant = status => {
    if (status === 'Activate') return 'success'
    if (status === 'Inactivate') return 'secondary'
    return 'primary'
  }

  return {
    // option
    categoryOptions,

    // method
    uploadImage,

    // data
    tableColumns,
    perPage,
    currentPage,
    totalCards,
    dataMeta,
    perPageOptions,
    searchQuery,
    categoryQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    draggableOptions,
    
    // UI
    resolveCardStatusVariant,
    refetchData,
  }
}
